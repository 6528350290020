import React, { useState, useEffect } from "react"
import * as Sentry from "@sentry/gatsby"

import { useApp } from "../../hooks/useApp"
import { loadCheckout, createBraintreeClient, createPaypalCheckoutInstance, handleCheckout, usePaymentData } from "../../hooks/usePayments"
import { useLanguage } from "../../hooks/useLanguage"
import { useRoutes } from "../../hooks/useRoutes"
import { useLocation } from "../../hooks/useLocation"
import { useSentry } from "../../hooks/useSentry"

export const withCheckout = Component => ({ name = `Checkout`, orderId }) => {
  const {
    config: {
      settings: { params },
    },
  } = useApp()
  const { customPaymentPolicy } = usePaymentData()
  const { getUrlParameter } = useRoutes()
  const { shopifyStore } = useLocation()
  const { sendSentryEvent } = useSentry()

  const [checkout, setCheckout] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [braintreeId, setBraintreeId] = useState(null)
  const [braintreeClient, setBraintreeClient] = useState(null)
  const [paypalCheckout, setPaypalCheckout] = useState(null)
  const [selectedOption, setSelectedOption] = useState(null)

  const [customerId, setCustomerId] = useState(null)
  const [redirectUrl, setRedirectUrl] = useState(null)

  const [checkoutError, setCheckoutError] = useState(null)
  const [serverError, setServerError] = useState(null)

  const locales = useLanguage(`payment`)

  useEffect(() => {
    const resolveParams = () => {
      const encodedParams = getUrlParameter(params.checkoutID)
      const decodedParamString = atob(encodedParams)
      const decodedParams = decodedParamString.split("&")

      for (const param of decodedParams) {
        if (param.includes("customer_id=")) {
          setCustomerId(param.substr("customer_id=".length))
        }
        if (param.includes("return_url=")) {
          setRedirectUrl(param.substr("return_url=".length))
        }
      }
    }
    resolveParams()
  }, [])

  useEffect(() => {
    if (customerId && shopifyStore) {
      const fetchCheckout = async () => {
        const result = await loadCheckout(customerId, orderId, shopifyStore)
        if (result) {
          setCheckout(result)
          setBraintreeId(result.braintreeCustomerId)
        }
        setIsLoading(false)
      }
      fetchCheckout()
    }
  }, [customerId, shopifyStore])

  useEffect(() => {
    if (!braintreeClient && checkout) {
      const createBTClient = async () => {
        const client = await createBraintreeClient(braintreeId, shopifyStore)
        setBraintreeClient(client)
      }
      createBTClient()
    }
  }, [braintreeId, shopifyStore])

  useEffect(() => {
    if (!paypalCheckout && braintreeClient) {
      const createPPClient = async () => {
        const client = await createPaypalCheckoutInstance(braintreeClient)
        setPaypalCheckout(client)
      }
      createPPClient()
    }
  }, [braintreeClient])

  useEffect(() => {
    if (paypalCheckout && shopifyStore) {
      const createPaypalButton = async () => {
        await paypalCheckout.loadPayPalSDK({
          vault: true,
        })
        window.paypal
          .Buttons({
            fundingSource: window.paypal.FUNDING.PAYPAL,

            createBillingAgreement: function () {
              return paypalCheckout.createPayment({
                flow: "vault", // Required
              })
            },

            onApprove: function (data) {
              setIsSubmitting(true)
              return paypalCheckout.tokenizePayment(data).then(function (payload) {
                // Submit `payload.nonce` to your server

                const nonce = payload.nonce
                const planId = selectedOption?.value
                if (!planId) {
                  console.error(`[ERROR] Invalid plan id`)
                  sendSentryEvent({
                    message: `[ERROR][${orderId}] Invalid plan id`,
                    tag: "paypal_checkout",
                    level: Sentry.Severity.Error,
                  })
                  setIsSubmitting(false)
                  return false
                }

                if (!customerId) {
                  console.error(`[ERROR] Invalid customer id`)
                  sendSentryEvent({
                    message: `[ERROR][${orderId}] Invalid customer id`,
                    tag: "paypal checkout",
                    level: Sentry.Severity.Error,
                  })
                  setIsSubmitting(false)
                  return false
                }

                handleCheckout(customerId, orderId, planId, nonce, shopifyStore)
                  .then(() => {
                    window.location.href = redirectUrl
                    setIsSubmitting(false)
                  })
                  .catch(err => {
                    console.error(`[ERROR] ${err.message}`)
                    sendSentryEvent({
                      message: `[handle paypal Checkout][${orderId}][ERROR] ${err.message}`,
                      tag: "paypal_checkout",
                      level: Sentry.Severity.Error,
                      error: err
                    })
                    setIsSubmitting(false)
                  })
              })
            },

            onCancel: function (data) {
              console.warn("PayPal payment cancelled")
              sendSentryEvent({
                message: `[handle paypal Checkout][${orderId}] PayPal payment cancelled}`,
                tag: "paypal_checkout",
                level: Sentry.Severity.Warning,
                responsePayload: `${JSON.stringify(data)}`,
              })
            },

            onError: function (err) {
              console.error("PayPal error", err)
              sendSentryEvent({
                message: `[handle paypal Checkout][${orderId}][ERROR] ${err}`,
                tag: "paypal_checkout",
                level: Sentry.Severity.Error,
                error: err,
              })
            },
          })
          .render("#paypal-button-checkout")
      }
      createPaypalButton()
    }
  }, [paypalCheckout, selectedOption, shopifyStore])

  const handleSubmit = async customerData => {
    setCheckoutError(null)
    setServerError(null)

    if (!shopifyStore) {
      setCheckoutError(`Internal error. Looks like something went wrong on our end. Please try again later or contact our support team.`)
      sendSentryEvent({
        message: `[handle Normal Checkout]${orderId}[ERROR] Missing shop name`,
        tag: "checkout",
        level: Sentry.Severity.Error,
      })
      return
    }

    setIsSubmitting(true)
    const braintreeClient = await createBraintreeClient(braintreeId, shopifyStore)

    const data = {
      creditCard: {
        number: customerData.cardNumber,
        cvv: customerData.securityCode,
        expirationDate: customerData.expirationDate,
        billingAddress: {
          postalCode: checkout.order.billingAddress.zip,
        },
      },
    }

    braintreeClient.request(
      {
        endpoint: "payment_methods/credit_cards",
        method: "post",
        data: data,
      },
      (err, response) => {
        if (!err) {
          const nonce = response.creditCards[0].nonce
          const planId = selectedOption?.value
          if (!planId) {
            console.error(`[ERROR] Invalid plan id`)
            sendSentryEvent({
              message: `[handle Normal Checkout][${orderId}][ERROR] Invalid plan id`,
              tag: "checkout",
              level: Sentry.Severity.Error,
            })
            setServerError(`[ERROR] Invalid amount`)
            return false
          }

          if (!customerId) {
            console.error(`[ERROR] Invalid customer id`)
            sendSentryEvent({
              message: `[handle Normal Checkout][${orderId}][ERROR] Invalid customer id`,
              tag: "checkout",
              level: Sentry.Severity.Error,
            })
            setServerError(`[ERROR] Invalid customer id`)
            return false
          }

          handleCheckout(customerId, orderId, planId, nonce, shopifyStore)
            .then(() => {
              window.location.href = redirectUrl
              setIsSubmitting(false)
            })
            .catch(err => {
              console.error(`[ERROR] ${err.message}`)
              setIsSubmitting(false)
              setServerError(`[ERROR] ${err.message}`)
              return false
            })
        } else {
          console.error(`[ERROR] ${err}`)
          sendSentryEvent({
            message: `[handle Normal Checkout][${orderId}][ERROR] payment details couldn’t be verified - ${JSON.stringify(data)}`,
            tag: "checkout",
            level: Sentry.Severity.Error,
            responsePayload: `${JSON.stringify(data)}`,
            error: err,
          })
          setIsSubmitting(false)
          setCheckoutError(`Your payment details couldn’t be verified. Check your card details and try again.`)
          return false
        }
      }
    )
  }

  Component.displayName = name
  return (
    <Component
      checkout={checkout}
      checkoutError={checkoutError}
      customPaymentPolicy={customPaymentPolicy}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      isSubmitting={isSubmitting}
      locales={locales}
      selectedOption={selectedOption}
      serverError={serverError}
      setSelectedOption={setSelectedOption}
    />
  )
}
