import React from "react"

import { withCheckout } from "./withCheckout"
import { CheckoutSection } from "./Section/CheckoutSection"

interface Props {
  checkout: any
  checkoutError: any
  customPaymentPolicy: any
  handleSubmit: Function
  isLoading: any
  isSubmitting: any
  locales: any
  selectedOption: any
  serverError: any
  setSelectedOption: any
}

export const Checkout = withCheckout(
  ({
    checkout,
    checkoutError,
    customPaymentPolicy,
    handleSubmit,
    isLoading,
    isSubmitting,
    locales,
    selectedOption,
    serverError,
    setSelectedOption,
  }: Props) => (
    <CheckoutSection
      checkout={checkout}
      checkoutError={checkoutError}
      customPaymentPolicy={customPaymentPolicy}
      isLoading={isLoading}
      isSubmitting={isSubmitting}
      locales={locales}
      onSubmit={handleSubmit}
      selectedOption={selectedOption}
      serverError={serverError}
      setSelectedOption={setSelectedOption}
    />
  )
)
